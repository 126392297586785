import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { grapqhURL } from "@helpers/configs";
import { getToken, logout } from "@services/auth";

import { createResolvers } from "./resolvers";

const httpLink = new HttpLink({
  uri: grapqhURL,
});

const authLink = setContext(async (_, { headers, ...context }) => {
  const token = getToken();

  return {
    ...context,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: error => Boolean(error),
  },
  delay: {
    initial: 300,
    jitter: true,
    max: Infinity,
  },
});

const errorLink = onError(async ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    await logout();
    graphQLErrors.map(({ locations, message, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    await logout();
    throw ErrorCode.requestTimeout;
  }
});

export const apollo = new ApolloClient({
  link: ApolloLink.from([authLink.concat(httpLink), retryLink, errorLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          transactions: {
            keyArgs: false,
            merge(existing, incoming) {
              let launches = [];

              if (existing && existing.launches) {
                launches = launches.concat(existing.launches);
              }
              if (incoming && incoming.launches) {
                launches = launches.concat(incoming.launches);
              }
              return {
                ...incoming,
                launches,
              };
            },
          },
        },
      },
    },
  }),
  resolvers: createResolvers(),
});
