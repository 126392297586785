import React from "react";
import packageJson from "../../package.json";
import { BookOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";

export const PREFIXED_URI = "/app";

export const authSelection = {
  login: "login",
  signup: "signup",
};

export const providers = {
  google: "google",
  facebook: "facebook",
};

export const providersQuery = {
  google: "id_token",
  facebook: "access_token",
};

export const paths = {
  home: `/home`,
  ledgers: `/ledgers`,
  access: `/access`,
  accounts: `/accounts`,
  profile: `/profile`,
  recurrent: `/recurrent`,
};

export const URLS = {
  home: `${PREFIXED_URI}${paths.home}`,
  ledgers: `${PREFIXED_URI}${paths.ledgers}`,
  accounts: `${PREFIXED_URI}${paths.accounts}`,
  login: `${PREFIXED_URI}${paths.access}`,
  profile: `${PREFIXED_URI}${paths.profile}`,
  recurrent: `${PREFIXED_URI}${paths.recurrent}`,
};

export const mobileMenu: Array<any> = [
  {
    text: "Home",
    Icon: (prop: any) => <HomeOutlined {...prop} />,
    url: URLS.home,
  },
  {
    text: "Ledger",
    Icon: (prop: any) => <BookOutlined {...prop} />,
    url: URLS.ledgers,
  },
  {
    text: "Login",
    logedInText: "Profile",
    Icon: (prop: any) => <UserOutlined {...prop} />,
    url: URLS.login,
    logedInURL: URLS.profile,
  },
];

export const messages = {
  errors: {
    login: "ID or password mismatch",
    network: "Network Error",
  },
  success: {
    addCart: "Succesfully Added to Cart!",
    addWishlist: "Succesfully Added to Wishlist!",
  },
  others: {
    adding: "Adding..",
  },
};

export const durationOpts = [
  { id: "daily", option: "Daily" },
  { id: "weekly", option: "Weekly" },
  { id: "monthly", option: "Monthly" },
  { id: "yearly", option: "Yearly" },
];

export const appDetails = {
  name: packageJson.name,
  version: packageJson.version,
};
