import { ApolloCache, Resolvers as BaseResolvers } from "@apollo/client";
import { apiURL } from "@helpers/configs";
import { getUser } from "@services/auth";

export type ResolverFn = (
  parent: any,
  args: any,
  cache: { cache: ApolloCache<any> }
) => any;

export interface ResolverMap {
  [field: string]: ResolverFn;
}

export interface Resolvers extends BaseResolvers {
  Mutation: ResolverMap;
  Query: ResolverMap;
}

export type CreateResolversFn = () => Resolvers;

export const createResolvers: CreateResolversFn = () => {
  return {
    Query: {
      accountSummaries: async () => {
        try {
          const token = getUser().token;

          const response = await fetch(`${apiURL}/account-summaries`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          return {
            data,
            __typename: "AccountSummaries",
          };
        } catch (err) {
          console.log(err);
        }
      },
      accountSummary: async (_: any, { id }: { id: string }) => {
        try {
          const token = getUser().token;

          const response = await fetch(`${apiURL}/account-summary/${id}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          return {
            data,
            __typename: "AccountSummary",
          };
        } catch (err) {
          console.log(err);
        }
      },
      transactSummaries: async () => {
        try {
          const token = getUser().token;

          const response = await fetch(`${apiURL}/transact-summaries`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          return {
            data,
            __typename: "TransactSummaries",
          };
        } catch (err) {
          console.log(err);
        }
      },
    },
  };
};
